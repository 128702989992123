export default {
	header: {
		p1: '당신의 독점',
		p2: '럭셔리 여행사',
		desc: '특가상품혜택으로 4,000개 이상의 글로벌 럭셔리 호텔과 연결',
		about: {
			title: 'HoteLux에 관하여',
			desc1: 'HoteLux는 즉시 예약 확인 서비스를 제공하는 회원제 럭셔리 여행사입니다. 전 세계적으로 ',
			desc2: '호평을 받는 럭셔리 호텔 그룹의 풍부한 포트폴리오를 통해 HoteLux는 안목 있는 여행자를 독점적이고',
			desc3: '초대 전용 럭셔리 에이전트 프로그램에 연결하여 맞춤형 여행의 본질을 재정의합니다.'
		}
	},
	levelAndPrice: {
		title: '맞춤형 숙박의 세계로 들어가기',
		img: 'https://qiniu.hoteluxapp.com//FjKh8LtM4uLdVHVEMIckZleaOptY_15.png',
		desc1: 'Elite, Elite Plus 및 1A Club이 럭셔리한 여행 경험을 어떻게 향상시킬 수 있는지 알아보세요.',
		desc2: '지금 앱을 다운로드하고  자신의 필요에 맞는 회원등급을 확인해 보세요.'
	},
	members: {
		p1: 'HoteLux 회원이 되시면',
		p2: '회원 전용으로 즐길 수 있는',
		benefits: '혜택',
		and: '및',
		promotions: '프로모션',
		including: '포함:'
	},
	partners: {
		title: 'Our Hotel Partners* ',
		subtitle: '*예시',
		tabs: {
			AsiaPacific: '아시아 태평양',
			Europe: '유럽',
			AfricaMiddleEast: '아프리카 & 중동',
			America: '미국'
		}
	},
	HotelGroupPartner: {
		title: 'Our Hotel Group Partners'
	},
	featured: {
		title: '다음과 같은 특징 포함',
		VOGUE: 'VOGUE',
		RobbReport: 'Robb  Report',
		PRESTIGE: 'PRESTIGE',
		YahooLife: 'Yahoo Life',
		LIFESTYLEASIA: 'LIFESTYLE ASIA',
		legend: '#legend',
		EsquireHK: 'Esquire HK'
	},
	footerBottom: {
		contact: '콘택트:',
		singapore: '싱가포르: 싱가포르 비치뷰 12호 아시아프라자 2인승 11-01호 018961',
		japan: '일본: 도쿄도 미나토구 도라노몬 1-17-1 도라노몬 힐즈 상업용 빌딩 15층 105-6415',
		china: '중국: 상해시 푸퉈구 산시 서북로 1438호 푸시대빌딩 9층',
    scan: '스캔하여 다운로드',
    copyRight: 'Copyrights ©HoteLux Pte. Ltd.',
		download: {
			logo: 'https://qiniu.hoteluxapp.com/FtIUbr5hfDzC0rIBGiMODme6EQTN_84.png?imageView2/2/format/jpg/interlace/1',
			text: '다운로드',
			code: 'https://qiniu.hoteluxapp.com/Fu8EkSf-e7f3CB-TD8kapYSoPoS3_41.png',
			android: {
				url: 'https://play.google.com/store/apps/details?id=com.hotelux',
				code: 'https://qiniu.hoteluxapp.com//FpKLvxIQdZbsobtCoy8Zj201_gqE.png'
			},
			ios: {
				url: 'https://apps.apple.com/us/app/hotelux/id1527979174',
				code: 'https://qiniu.hoteluxapp.com//FtbLZTxdKPdEvqbggaiJ-D85hH_K.png'
			}
		}
	}
}
