import { createRouter, createWebHashHistory } from 'vue-router'
import http from '@/utils/http'
import i18n from '../i18n'

const routes = [
	{
		path: '/',
		name: 'pc',
		component: () => import('../views/pc.vue')
	},
	{
		path: '/notice',
		name: 'notice',
		component: () => import('../views/notice.vue')
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

const regionLangMap = {
	jp: 'jp',
	kr: 'ko',
	th: 'th',
	hk: 'zhTw'
}
const setDefaultLanguage = async () => {
	try {
		const res = await http.get('biz/util/ipInfo')
		let result = {
			redirect: false,
			url: '',
			language: ''
		}
		if (res.success) {
			const ipInfo = res.ipInfo
			const country_id = ipInfo.country_id
			const _country_id = country_id.toLowerCase()
			const hostLg = window.location.host.split('.')[0]
			const defaultRegions = Object.keys(regionLangMap)
			if (_country_id !== hostLg) {
				if (defaultRegions.includes(_country_id)) {
					result.redirect = true
					result.url = `https://${_country_id}.hoteluxapp.com`
				}
			}
			if (defaultRegions.includes(_country_id)) {
				result.language = regionLangMap[_country_id]
			}
		}
		return result
	} catch (error) {
		return {
			redirect: false,
			url: '',
			language: ''
		}
	}
}

router.beforeEach(async (to, from, next) => {
	const config = await setDefaultLanguage()
	if (config.language) {
		i18n.global.locale = config.language
	}
	if (!config.redirect) {
		next()
	} else {
		window.location.href = config.url
	}
})

export default router
