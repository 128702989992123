export default {
	header: {
		p1: 'เอ็กซ์คลูซีฟของคุณ',
		p2: 'ตัวแทนท่องเที่ยวสุดหรู',
		desc: 'เชื่อมต่อคุณกับโรงแรมหรูระดับโลกกว่า 4,000 แห่งพร้อมสิทธิประโยชน์ที่ไม่เคยมีมาก่อน',
		about: {
			title: 'เกี่ยวกับHoteLux',
			desc1: 'HoteLux เป็นตัวแทนการท่องเที่ยวสุดหรูที่เน้นสมาชิกซึ่งเสนอการยืนยันการจองทันที',
			desc2: 'ด้วยผลงานอันหลากหลายของกลุ่มโรงแรมหรูที่ได้รับการยกย่องทั่วโลก HoteLux เชื่อมโยงนักเดินทางที่มีวิสัยทัศน์เข้ากับความพิเศษเฉพาะตัว',
			desc3: 'และโปรแกรมตัวแทนหรูหราที่ได้รับเชิญเท่านั้น ซึ่งกำหนดนิยามใหม่ของการเดินทางตามความต้องการ.'
		}
	},
	levelAndPrice: {
		title: 'ก้าวเข้าสู่โลกของที่พักที่ปรับแต่งได้',
		img: 'https://qiniu.hoteluxapp.com//FsMdvFRNx88tmwFSExgfndRSolal_15.png',
		desc1: 'มาดูกันว่า Elite, Elite Plus และ 1A Club จะช่วยยกระดับประสบการณ์การเดินทางสุดหรูของคุณได้อย่างไร',
		desc2: 'ดาวน์โหลดแอปตอนนี้เพื่อค้นหาว่าเลเยอร์ไหนเหมาะกับความต้องการของคุณ'
	},
	members: {
		p1: 'ในฐานะสมาชิกของ HotelLux คุณจะเป็น',
		p2: 'สามารถเพลิดเพลินกับสมาชิกพิเศษ',
		benefits: 'ประโยชน์',
		and: 'และ',
		promotions: 'โปรโมชั่นพิเศษ',
		including: 'รวมทั้ง:'
	},
	partners: {
		title: 'พันธมิตรโรงแรมของเรา* ',
		subtitle: '*ตัวอย่าง',
		tabs: {
			AsiaPacific: 'เอเชีย & แปซิฟิก',
			Europe: 'ยุโรป',
			AfricaMiddleEast: 'แอฟริกา & ตะวันออก กลาง',
			America: 'อเมริกา'
		}
	},
	HotelGroupPartner: {
		title: 'พันธมิตรกลุ่มโรงแรมของเรา'
	},
	featured: {
		title: 'As Featured In',
		VOGUE: 'VOGUE',
		RobbReport: 'Robb  Report',
		PRESTIGE: 'PRESTIGE',
		YahooLife: 'Yahoo Life',
		LIFESTYLEASIA: 'LIFESTYLE ASIA',
		legend: '#legend',
		EsquireHK: 'Esquire HK',
		EsquireHK: 'Esquire HK'
	},
	footerBottom: {
		contact: 'การเชื่อมต่อ:',
		singapore: 'สิงคโปร์:  #11-01, Asia Square Tower 2, 12 Marina View, Singapore 018961',
		japan: 'ญี่ปุ่น: 15th Floor, Toranomon Hills Business Tower, 1-17-1 Toranomon, Minato-ku, Tokyo, 105-6415',
		china: 'จีน: 9F, Fortune Times Building No.1438 North Shaanxi Road, Putuo, Shanghai',
    scan: 'สแกนเพื่อดาวน์โหลด',
    copyRight: 'Copyrights ©HoteLux Pte. Ltd.',
		download: {
			logo: 'https://qiniu.hoteluxapp.com/FtIUbr5hfDzC0rIBGiMODme6EQTN_84.png?imageView2/2/format/jpg/interlace/1',
			text: 'ดาวน์โหลด',
			code: 'https://qiniu.hoteluxapp.com/Fu8EkSf-e7f3CB-TD8kapYSoPoS3_41.png',
			android: {
				url: 'https://play.google.com/store/apps/details?id=com.hotelux',
				code: 'https://qiniu.hoteluxapp.com//FpKLvxIQdZbsobtCoy8Zj201_gqE.png'
			},
			ios: {
				url: 'https://apps.apple.com/us/app/hotelux/id1527979174',
				code: 'https://qiniu.hoteluxapp.com//FtbLZTxdKPdEvqbggaiJ-D85hH_K.png'
			}
		}
	}
}
