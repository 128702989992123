
export default {
	header: {
		p1: 'あなただけの',
		p2: 'ラグジュアリートラベルエージェント',
		desc: '世界4,000軒以上のラグジュアリーホテルとつながり、これまでにない特典をあなたに。',
		about: {
			title: 'HoteLuxについて',
			desc1: 'HoteLuxは即時に予約確認を行える会員制のラグジュアリートラベルエージェントです。',
			desc2: '世界的に高く評価されたラグジュアリーホテルグループを豊富に有するHoteLuxは、',
			desc3: '旅行経験が豊富なお客様を招待制限定のラグジュアリープログラムへと導き、本当のオーダーメイドの旅とは何かを問い直しています。'
		}
	},
	levelAndPrice: {
		title: 'オーダーメイドの滞在の世界へ足を踏み入れよう',
		img: 'https://qiniu.hoteluxapp.com//Fr8mgVlKBZoBAH-5P3MKqTjg5voJ_15.png',
		desc1: 'エリート、エリートプラス、1Aクラブの会員になると、いかに豪華な旅行体験が可能になるかをご確認いただけます。',
		desc2: '今すぐアプリをダウンロードして、お客様のニーズに合ったレベルをお確かめください。'
	},
	members: {
		p1: 'HoteLux会員になると、',
		p2: '以下のような会員限定の',
		benefits: '特典',
		and: '&',
		promotions: 'プロモーション',
		including: 'をご利用いただけます:'
	},
	partners: {
		title: '提携ホテル',
		subtitle: '',
		tabs: {
			AsiaPacific: 'アジア太平洋地域',
			Europe: 'ヨーロッパ',
			AfricaMiddleEast: 'アフリカ・中東',
			America: 'アメリカ'
		}
	},
	HotelGroupPartner: {
		title: 'ホテルグループパートナー'
	},
	featured: {
		title: '特集記事',
		VOGUE: 'VOGUE',
		RobbReport: 'Robb  Report',
		PRESTIGE: 'PRESTIGE',
		YahooLife: 'Yahoo Life',
		LIFESTYLEASIA: 'LIFESTYLE ASIA',
		legend: '#legend',
		EsquireHK: 'Esquire HK'
	},
	footerBottom: {
		contact: 'お問い合わせ:',
		singapore: 'シンガポール: 〒018961マリナツースクエアタワーマリナビュー12 #11-01',
		japan: '日本: 〒105-6415東京都港区虎ノ門ヒルズビジネスタワー15階',
		china: '中国: 中国上海市普陀区陝西北路1438号財富時代大厦9階',
    scan: 'スキャンしてダウンロードする',
    copyRight: '著作権 ©HoteLux Pte. Ltd.',
		download: {
			logo: 'https://qiniu.hoteluxapp.com/FtIUbr5hfDzC0rIBGiMODme6EQTN_84.png?imageView2/2/format/jpg/interlace/1',
			text: 'ダウンロード',
			code: 'https://qiniu.hoteluxapp.com/Fu8EkSf-e7f3CB-TD8kapYSoPoS3_41.png',
			android: {
				url: 'https://play.google.com/store/apps/details?id=com.hotelux',
				code: 'https://qiniu.hoteluxapp.com//FpKLvxIQdZbsobtCoy8Zj201_gqE.png'
			},
			ios: {
				url: 'https://apps.apple.com/us/app/hotelux/id1527979174',
				code: 'https://qiniu.hoteluxapp.com//FtbLZTxdKPdEvqbggaiJ-D85hH_K.png'
			}
		}
	}
}
