export default {
	header: {
		p1: 'Your Exclusive',
		p2: 'Luxury Travel Agent',
		desc: 'Connecting you to 4,000+ global luxury hotels with unprecedented benefits',
		about: {
			title: 'About HoteLux',
			desc1: 'HoteLux is a membership-based luxury travel agent offering instant booking confirmation.',
			desc2: 'With a rich portfolio of globally acclaimed luxury hotel groups, HoteLux connects discerning travelers to',
			desc3: 'exclusive and invite-only luxury agent programs, redefining the essence of bespoke travel.'
		}
	},
	levelAndPrice: {
		title: 'Step Into a World of Bespoke Stays',
		img: 'https://qiniu.hoteluxapp.com//FoExqIln1a9IdQja8wbOGs5qsHRE_15.png',
		desc1: 'Find out how Elite, Elite Plus, and 1A Club can enhance your luxury travel experience.',
		desc2: 'Download the app today and discover which tier suits your needs.'
	},
	members: {
		p1: 'As a HoteLux member, you will be able',
		p2: 'to enjoy member exclusive',
		benefits: ' benefits',
		and: ' and',
		promotions: 'promotions',
		including: ', including:'
	},
	partners: {
		title: 'Our Hotel Partners* ',
		subtitle: '*Examples, non-exhaustive',
		tabs: {
			AsiaPacific: 'Asia & Pacific',
			Europe: 'Europe',
			AfricaMiddleEast: 'Africa & Middle East',
			America: 'America'
		}
	},
	HotelGroupPartner: {
		title: 'Our Hotel Group Partners'
	},
	featured: {
		title: 'As Featured In',
		VOGUE: 'VOGUE',
		RobbReport: 'Robb  Report',
		PRESTIGE: 'PRESTIGE',
		YahooLife: 'Yahoo Life',
		LIFESTYLEASIA: 'LIFESTYLE ASIA',
		legend: '#legend',
		EsquireHK: 'Esquire HK'
	},
	footerBottom: {
		contact: 'Contact:',
		singapore: 'Singapore:  #11-01, Asia Square Tower 2, 12 Marina View, Singapore 018961',
		japan: 'Japan: 15th Floor, Toranomon Hills Business Tower, 1-17-1 Toranomon, Minato-ku, Tokyo, 105-6415',
		china: 'China: 9F, Fortune Times Building No.1438 North Shaanxi Road, Putuo, Shanghai',
    scan: 'Scan to Download',
    copyRight: 'Copyrights ©HoteLux Pte. Ltd.',
		download: {
			logo: 'https://qiniu.hoteluxapp.com/FtIUbr5hfDzC0rIBGiMODme6EQTN_84.png?imageView2/2/format/jpg/interlace/1',
			text: 'Download',
			code: 'https://qiniu.hoteluxapp.com/Fu8EkSf-e7f3CB-TD8kapYSoPoS3_41.png',
			android: {
				url: 'https://play.google.com/store/apps/details?id=com.hotelux',
				code: 'https://qiniu.hoteluxapp.com//FpKLvxIQdZbsobtCoy8Zj201_gqE.png'
			},
			ios: {
				url: 'https://apps.apple.com/us/app/hotelux/id1527979174',
				code: 'https://qiniu.hoteluxapp.com//FtbLZTxdKPdEvqbggaiJ-D85hH_K.png'
			}
		}
	}
}
