
export default {
	header: {
		p1: '您的獨家',
		p2: '奢華旅行代理',
		desc: '將您與全球4,000多家奢華酒店連接起來，享受前所未有的優惠',
		about: {
			title: '關於 HoteLux',
			desc1: 'HoteLux 是一家基於會員制度的奢華旅行代理商，提供即時預訂確認。',
			desc2: '憑藉豐富的全球知名奢華酒店集團組合，HoteLux 將獨具慧眼的旅行者',
			desc3: '與僅限邀請的獨家奢華代理計劃聯繫起來，重新定義客製化旅行的本質。'
		}
	},
	levelAndPrice: {
		title: '走進客製化住宿的世界',
		img: 'https://qiniu.hoteluxapp.com//FnrrG2DN3GgcL0Vog3EoP-UUO2By_15.png',
		desc1: '了解Elite、Elite Plus和1A Club如何提升您的奢華旅行體驗。',
		desc2: '立即下載應用程序，發現適合您需求的會員級別。'
	},
	members: {
		p1: '作為 HoteLux 會員，您將',
		p2: '享受會員專屬',
		benefits: '福利',
		and: '及',
		promotions: '促銷',
		including: '包含:'
	},
	partners: {
		title: '我們的酒店合作夥伴* ',
		subtitle: '*範例',
		tabs: {
			AsiaPacific: '亞洲及太平洋地區',
			Europe: '歐洲',
			AfricaMiddleEast: '非洲及中東地區',
			America: '美洲'
		}
	},
	HotelGroupPartner: {
		title: '我們的酒店集團合作夥伴'
	},
	featured: {
		title: '專刊報導',
		VOGUE: 'VOGUE',
		RobbReport: 'Robb  Report',
		PRESTIGE: 'PRESTIGE',
		YahooLife: 'Yahoo Life',
		LIFESTYLEASIA: 'LIFESTYLE ASIA',
		legend: '#legend',
		EsquireHK: 'Esquire HK'
	},
	footerBottom: {
		contact: '聯絡方式:',
		singapore: '新加坡：濱海灣12號亞洲廣場大廈2座11樓，郵政編碼：018961',
		japan: '日本：東京都港區虎之門1-17-1虎之門之丘商業大樓15樓',
		china: '中國：上海市普陀區陝西北路1438號財富時代大廈9樓',
    scan: '掃碼下載',
    copyRight: '版權所有 ©HoteLux Pte. Ltd.',
		download: {
			logo: 'https://qiniu.hoteluxapp.com/FtIUbr5hfDzC0rIBGiMODme6EQTN_84.png?imageView2/2/format/jpg/interlace/1',
			text: 'Download',
			code: 'https://qiniu.hoteluxapp.com/Fu8EkSf-e7f3CB-TD8kapYSoPoS3_41.png',
			android: {
				url: 'https://play.google.com/store/apps/details?id=com.hotelux',
				code: 'https://qiniu.hoteluxapp.com//FpKLvxIQdZbsobtCoy8Zj201_gqE.png'
			},
			ios: {
				url: 'https://apps.apple.com/us/app/hotelux/id1527979174',
				code: 'https://qiniu.hoteluxapp.com//FtbLZTxdKPdEvqbggaiJ-D85hH_K.png'
			}
		}
	}
}
