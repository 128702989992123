import { createI18n } from 'vue-i18n'

import en from './en'
import ko from './ko'
import jp from './jp'
import th from './th'
import zhTw from './zhTw'

const messages = {
	en,
	ko,
	jp,
	th,
	zhTw
}

let _local = window.navigator.language || 'en'
if (_local === 'zh-tw') {
	_local = 'zh-TW'
}
if (_local === 'zh-CN') {
	_local = 'en'
}
const i18n = createI18n({
	locale: _local,
	globalInjection: true,
	messages
})
i18n.global.locale = _local

export default i18n
