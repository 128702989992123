import axios from 'axios'
const service = axios.create({
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
  baseURL: '/services',
  timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default {
  get: (url, data = {}) => {
    return service({
      url: url,
      method: 'get',
      params: data
    })
  },
  post: (url, data = {}) => {
    return service({
      url: url,
      method: 'post',
      data: data
    })
  },
  patch: (url, data = {}) => {
    return service({
      url: url,
      method: 'patch',
      data
    })
  },
  delete: (url, data = {}) => {
    return service({
      url: url,
      method: 'delete',
      data
    })
  }
}
